<template>
  <div>
    <b-upload
      :value="files"
      size="is-small"
      :multiple="!!isMultiFileUpload"
      :accept="acceptedFormats"
      :disabled="isUploading"
      :class="[
        'file-upload',
        isUploading ? 'upload-disabled' : ''
      ]"
      @input="handleUpdate($event)"
      v-on="fieldEvents"
    >
      <span class="file-cta">
        <img
          v-if="isUploading"
          :src="require('@/assets/spinner.gif')"
          class="upload-loader"
          alt="Loading"
        >
        <b-icon
          v-else
          class="file-icon"
          size="is-small"
          icon="upload"
        />
        <span class="file-label">{{ getTranslation(`${field.id}_label`, field.properties.basic.label) }}</span>
      </span>
      <span
        v-if="filesToBeUploaded.length"
        class="file-name"
        style="border: none!important;"
      >
        {{ filesToBeUploaded.map(file => file.name).join(',') }}
      </span>
    </b-upload>
    <b-icon 
      v-if="filesToBeUploaded.length"
      icon="close"
      type="is-danger"
      class="clear-upload-btn"
      @click.native="clearFileUploads()"
    />
  </div>
</template>

<script >
import { computed, ref, set } from '@vue/composition-api';
import axios from 'axios';
import { useApplicationStore } from '@/store/applicationStore';
import { getExpressionParser } from '@/lib/nuclicore-core';
import { useBuefy } from '@/hooks/buefy';
import { useLocalizationStore } from '@/store/localizationStore';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  },
  customEventHandler: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const expressionParser = getExpressionParser();
  const props = __props;
  const emit = __ctx.emit;
  const applicationStore = useApplicationStore();
  const buefy = useBuefy();
  const localizationStore = useLocalizationStore();
  const {
    getTranslation
  } = localizationStore;
  const isUploading = ref(false);
  const files = computed({
    get() {
      return applicationStore.uploadedFiles[props.field.id] || (isMultiFileUpload.value ? [] : {});
    },
    set(value) {
      if (props.field.id in applicationStore.uploadedFiles) {
        applicationStore.uploadedFiles[props.field.id] = value;
      } else {
        set(applicationStore.uploadedFiles, props.field.id, value);
      }
    }
  });
  const isMultiFileUpload = computed(() => !!props.field.properties.basic?.multiple);
  const filesToBeUploaded = computed(() => {
    if (applicationStore.uploadedFiles[props.field.id]) {
      return isMultiFileUpload.value ? (files.value || []).map(file => file) : [files.value];
    }
    return [];
  });
  const acceptedFormats = computed(() => {
    const validationProperties = props.field.properties.validation;
    return !validationProperties.allowAllFormats && validationProperties.allowed?.length ? validationProperties.allowed.join(',') : validationProperties.isCustomFormat ? validationProperties.customFormat : '';
  });
  const handleUpdate = async uploadedFile => {
    try {
      isUploading.value = true;
      let sizeRestriction = parseFloat(props.field.properties?.validation?.sizeRestriction);
      if (uploadedFile?.size && !isNaN(sizeRestriction)) {
        const fileSize = uploadedFile.size / (1024 * 1024); // convert size to MB
        if (sizeRestriction < 0) sizeRestriction = 0;else if (sizeRestriction > 100) sizeRestriction = 100;
        if (fileSize > sizeRestriction) {
          buefy.toast.open({
            duration: 5000,
            message: `File size is greater than ${sizeRestriction} MB`,
            position: 'is-top',
            type: 'is-danger'
          });
          return;
        }
      }
      files.value = uploadedFile;
      const uploadedFileNames = await upload();
      emit('update', {
        value: uploadedFileNames
      });
      if (props.customEventHandler) {
        props.customEventHandler();
      }
    } catch (err) {
      console.error(err);
      const {
        uploadFailureMessage
      } = props.field.properties.validation;
      if (uploadFailureMessage) {
        const failureMessage = (await expressionParser.parse(uploadFailureMessage, 'strip')).value;
        emit('error', {
          message: failureMessage
        });
      }
    } finally {
      isUploading.value = false;
    }
  };
  const upload = async () => {
    const formData = new FormData();
    const {
      hasCustomFileName,
      customFileName
    } = props.field.properties.basic;
    const appMeta = {
      moduleId: applicationStore.currentNode?.data?.module_id,
      applicationId: applicationStore.currentNode?.data?.application_id,
      sessionId: applicationStore.executionResult.session,
      environment: applicationStore.deploymentInfo.environment_name,
      screenName: applicationStore.currentNode?.data?.name,
      fieldName: props.field?.properties?.basic?.label
    };
    Object.entries(appMeta).forEach(([key, value]) => {
      formData.append(`_${key}`, value);
    });
    const uploadedFileNames = [];
    await Promise.all(filesToBeUploaded.value.map(async (file, index) => {
      if (hasCustomFileName && customFileName) {
        let parsedFileName = (await expressionParser.parse(customFileName, 'strip')).value;
        if (isMultiFileUpload.value) {
          // appending index to ensure file names remain unique in case of multi-file upload
          parsedFileName += index + 1;
        }
        if (parsedFileName) {
          const [fileExtension] = file.name.split('.').slice(-1);
          const fileName = `${parsedFileName}.${fileExtension.toLowerCase()}`;
          formData.append(fileName, file);
          uploadedFileNames.push(fileName);
        }
      } else {
        formData.append(file.name, file);
        uploadedFileNames.push(file.name);
      }
    }));

    // ignore replacing with services for now
    await axios({
      method: 'POST',
      url: window.env.VUE_APP_APP_SERVICE_DOMAIN + '/upload',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: {
        hasCustomFileName: !!(hasCustomFileName && customFileName)
      }
    });

    // since upload API clears older uploads, no need to attach current field in deleted uploads map
    if (props.field.id in applicationStore.deletedUploads) {
      applicationStore.deletedUploads[props.field.id] = false; // triggers update since due to vue 2 reactivity, it can't detect property removal from objects
      delete applicationStore.deletedUploads[props.field.id];
    }
    return uploadedFileNames;
  };
  const clearFileUploads = () => {
    if (props.field.id in applicationStore.uploadedFiles) {
      applicationStore.uploadedFiles[props.field.id] = {};
      delete applicationStore.uploadedFiles[props.field.id];
    }
    if (!(props.field.id in applicationStore.deletedUploads)) {
      set(applicationStore.deletedUploads, props.field.id, true);
    }
    isUploading.value = false;
    emit('update', {
      value: ['']
    });
  };
  return {
    getTranslation,
    isUploading,
    files,
    isMultiFileUpload,
    filesToBeUploaded,
    acceptedFormats,
    handleUpdate,
    clearFileUploads
  };
};
export default __sfc_main;
</script>

<style lang="scss">
.file-upload {
  &.upload-disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  .clear-upload-btn {
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
  .upload-loader {
    width: 2rem;
  }
}
</style>
